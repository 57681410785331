import {
  Schema,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { extraValidatorTypes } from '~/components/Form/validators';
import { GetViperAssetQuery } from '~/__generated-asset__/graphql';
import {} from '../../../../schemas/tools';
import { useTranslation } from 'react-i18next';
import { useViperAssetFormOptions } from '~/pages/ViperAsset/FormOptionsContext';

interface GetCommercialEditSchemaProps {
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: boolean;
}

export const useCommercialEditSchema = ({
  viperAsset,
}: GetCommercialEditSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  const { customers } = useViperAssetFormOptions();

  return {
    fields: [
      {
        component: componentTypes.DATE_PICKER,
        name: 'dateOfPurchase',
        label: t('dateOfPurchase'),
        sx: { gridColumnEnd: 'span 2' },
        initialValue: viperAsset?.dateOfPurchase,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
      {
        component: componentTypes.SELECT,
        name: 'endClientId',
        label: t('endClient'),
        helperText: t('end-user-helper'),
        options: customers,
        initialValue: viperAsset?.endClient,
        isClearable: true,
      },
      {
        component: componentTypes.SELECT,
        name: 'customerId',
        label: t('customer'),
        helperText: t('customer-helper'),
        options: customers,
        initialValue: viperAsset?.customer,
        isRequired: true,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
    ],
  };
};

import { Schema, componentTypes } from '@data-driven-forms/react-form-renderer';
import { extraValidatorTypes } from '~/components/Form/validators';
import { GetViperAssetQuery } from '~/__generated-asset__/graphql';
import { useTranslation } from 'react-i18next';
import { useViperAssetFormOptions } from '~/pages/ViperAsset/FormOptionsContext';

interface GetManufacturingEditSchemaProps {
  viperAsset?: GetViperAssetQuery['viperAsset'];
  editing?: boolean;
}

export const useManufacturingEditSchema = ({
  viperAsset,
}: GetManufacturingEditSchemaProps): Schema => {
  const { t } = useTranslation('viper-asset-forms');

  const { manufacturers } = useViperAssetFormOptions();

  return {
    fields: [
      {
        isClearable: true,
        component: componentTypes.SELECT,
        name: 'manufacturerId',
        label: t('manufacturer'),
        options: manufacturers,
        initialValue: viperAsset?.manufacturer,
      },
      {
        component: componentTypes.DATE_PICKER,
        name: 'dateOfManufacture',
        label: t('dateOfManufacture'),
        initialValue: viperAsset?.dateOfManufacture,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
        ],
      },
    ],
  };
};

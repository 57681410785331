import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetCreateViperServiceOptions } from '~/queries-asset/viper-service';
import { getVLifeCreateSchema } from './schemas/create/customerAsset/vLifeCreateSchema';

export const useVLifeCreateSchema = () => {
  const { t } = useTranslation('customer-asset-forms');
  const { data: createViperServiceOptions, loading } = useGetCreateViperServiceOptions();

  const vlifeCreateSchema = useMemo(
    () => getVLifeCreateSchema({ t, createViperServiceOptions }),
    [createViperServiceOptions, t]
  );

  return {
    vlifeCreateSchema,
    loading,
  };
};

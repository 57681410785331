import {
  AnyObject,
  FieldApi,
  FormOptions,
  Schema,
  componentTypes,
  validatorTypes,
} from '@data-driven-forms/react-form-renderer';
import { TFunction } from 'i18next';
import { extraValidatorTypes } from '~/components/Form/validators';
import {
  AllVLifeConfigFieldsFragment,
  GetCreateChannelOptionsQuery,
} from '~/__generated-asset__/graphql';
import { isLicenceActive } from '~/pages/CustomerAssets/utils';

interface GetVLifeEditSchemaProps {
  t: TFunction;
  vlifeConfig?: AllVLifeConfigFieldsFragment | null;
  vlifeLicenceTypes?: { id: string | number | boolean; name: string }[];
  vlifeVersions?: { id: string | number | boolean; name: string }[];
  deactivationReasons?: GetCreateChannelOptionsQuery['deactivationReasons'];
  editing?: boolean;
  creating?: boolean;
  isUpcoming?: boolean;
}

/**
 * This schema also serves as the create schema returned by getVLifeCreateSchema
 */
export function getVLifeEditSchema({
  t,
  vlifeConfig,
  vlifeLicenceTypes,
  vlifeVersions,
  deactivationReasons,
  editing,
  creating,
  isUpcoming,
}: GetVLifeEditSchemaProps): Schema {
  const createSchema = {
    fields: [
      {
        name: 'flaggedForDecommission',
        label: t('customer-asset-forms::v-life/flagged-for-decommission'),
        component: componentTypes.CHECKBOX,
        type: 'boolean',
        isRequired: false,
        hideField: isUpcoming ? true : !isLicenceActive(vlifeConfig),
        initialValue: vlifeConfig?.flaggedForDecommission,
      },
      {
        name: 'flaggedForDecommissionDate',
        label: t('customer-asset-forms::v-life/flagged-for-decommission-date'),
        component: componentTypes.DATE_PICKER,
        isRequired: false,
        condition: {
          when: 'flaggedForDecommission',
          is: true,
          then: {
            visible: true,
          },
          else: {
            visible: false,
            set: {
              flaggedForDecommissionDate: null,
            },
          },
        },
        hideField: isUpcoming ? true : !isLicenceActive(vlifeConfig),
        initialValue: vlifeConfig?.flaggedForDecommissionDate,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
          (value: string) => {
            if (
              !value ||
              !vlifeConfig ||
              !vlifeConfig?.startDate ||
              !vlifeConfig.expiryDateCommToCustomer
            ) {
              return undefined;
            }
            const date = new Date(value);
            const vLifeStartDate = new Date(vlifeConfig.startDate);
            const vLifeEndDate = new Date(vlifeConfig.expiryDateCommToCustomer);

            if (
              date.getTime() < vLifeStartDate.getTime() ||
              date.getTime() > vLifeEndDate.getTime()
            ) {
              return t(
                'customer-asset-forms::v-life/validation/flagged-for-decommission-date-outside-range'
              );
            }
            return undefined;
          },
        ],
      },
      {
        name: 'vlifeVersion',
        label: t('customer-asset-forms::v-life/version'),
        component: componentTypes.SELECT,
        isRequired: true,
        initialValue: vlifeConfig?.vlifeVersion,
        options: vlifeVersions,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
        sx: { gridColumnEnd: 'span 2' },
      },
      {
        name: 'purchaseOrderNumber',
        label: t('customer-asset-forms::v-life/purchase-order-number'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        initialValue: vlifeConfig?.vlifeLicence.purchaseOrderNumber,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'vlifeLicenceType',
        label: t('customer-asset-forms::v-life/licence-type'),
        component: componentTypes.SELECT,
        isRequired: true,
        initialValue: vlifeConfig?.vlifeLicence.vlifeLicenceType,
        options: vlifeLicenceTypes,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'startDate',
        label: t('customer-asset-forms::v-life/start-date'),
        component: componentTypes.DATE_PICKER,
        isRequired: true,
        initialValue: vlifeConfig?.startDate,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
        ],
        helperText: t('customer-asset-forms::v-life/start-date-helper'),
      },
      {
        name: 'expiryDateCommToCustomer',
        label:
          vlifeConfig?.isDisabled && editing
            ? t('customer-asset-forms::v-life/disable-date')
            : t('customer-asset-forms::v-life/expiry-date-comm-to-customer'),

        component: componentTypes.DATE_PICKER,
        isRequired: true,
        initialValue: vlifeConfig?.expiryDateCommToCustomer,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
        ],
        helperText:
          vlifeConfig?.isDisabled && editing
            ? undefined
            : t('customer-asset-forms::v-life/expiry-date-comm-to-customer-helper'),
      },
      {
        name: 'expiryDateInConfig',
        label: t('customer-asset-forms::v-life/expiry-date-in-config'),
        component: componentTypes.DATE_PICKER,
        isRequired: true,
        initialValue: vlifeConfig?.expiryDateInConfig,
        hideField: vlifeConfig?.isDisabled,
        validate: [
          { type: extraValidatorTypes.VALID_DATE, message: t('viper::invalid-date') },
          { type: validatorTypes.REQUIRED, message: t('viper::required') },
        ],
        sx: { gridColumnEnd: 'span 2' },
        helperText: t('customer-asset-forms::v-life/expiry-date-in-config-helper'),
        resolveProps: (
          props: AnyObject,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _field: FieldApi<any, HTMLElement>,
          { getState }: FormOptions
        ) => {
          if (!vlifeConfig?.isDisabled) {
            return props;
          }
          const initialValue = getState().values.expiryDateCommToCustomer;
          return {
            ...props,
            initialValue,
          };
        },
      },
      {
        name: 'deactivationReason',
        label: t('customer-asset-forms::v-life/deactivation-reason'),
        component: componentTypes.SELECT,
        isRequired: vlifeConfig?.isDisabled && !vlifeConfig.resumptionDate,
        initialValue: vlifeConfig?.deactivationReason,
        options: deactivationReasons,
        validate:
          vlifeConfig?.isDisabled && !vlifeConfig.resumptionDate
            ? [{ type: validatorTypes.REQUIRED, message: t('viper::required') }]
            : undefined,
      },
      {
        name: 'starjarProjectNumber',
        label: t('customer-asset-forms::v-life/star-jar-project-number'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        initialValue: vlifeConfig?.vlifeLicence.starjarProjectNumber,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'salesOrderNumber',
        label: t('customer-asset-forms::v-life/sales-order-number'),
        component: componentTypes.TEXT_FIELD,
        isRequired: true,
        initialValue: vlifeConfig?.vlifeLicence.salesOrderNumber,
        validate: [{ type: validatorTypes.REQUIRED, message: t('viper::required') }],
      },
      {
        name: 'notes',
        label: t('customer-asset-forms::v-life/general-notes'),
        component: componentTypes.TEXTAREA,
        sx: { gridColumnEnd: 'span 2' },
        initialValue: vlifeConfig?.notes,
      },
    ].filter(
      (item) =>
        item.name !== 'deactivationReason' ||
        (vlifeConfig?.isDisabled && !vlifeConfig?.resumptionDate)
    ),
  };
  return creating
    ? createSchema
    : {
        ...createSchema,
        fields: [
          {
            name: 'id',
            label: t('customer-asset-forms::v-life/id'),
            component: componentTypes.PLAIN_TEXT,
            isRequired: true,
            initialValue: vlifeConfig?.id,
            sx: { gridColumnEnd: 'span 2' },
          },
          ...createSchema.fields,
        ],
      };
}

import { formatDate } from '~/utils/date-utils';
import { StatusProps } from '~/components/Status';
import {
  GetChannelQuery,
  VLifeStatus as GraphQLVLifeStatus,
} from '~/__generated-asset__/graphql';
import { useTranslation } from 'react-i18next';
import { VLifeStatus } from '~/pages/CustomerAssets/types';

export function useStatusProps({
  data,
  editing,
  onDeactivatedClick,
  onActivatedClick,
}: {
  data: GetChannelQuery | undefined;
  editing: string | undefined;
  onDeactivatedClick: () => void;
  onActivatedClick: () => void;
}): StatusProps {
  const { t } = useTranslation('disable-v-life-config');

  const vLifeStatus = data?.channel?.mostRecentVLifeConfig?.vLifeStatus;

  switch (vLifeStatus) {
    case GraphQLVLifeStatus.ActiveTrial:
      return {
        vLifeStatus: VLifeStatus.TRIAL,
        title: t('v-life'),
        description: data?.channel?.mostRecentVLifeConfig?.isDisabled
          ? t('trial-future', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig.expiryDateCommToCustomer),
            })
          : t('trial'),
        ctaColor: 'primary',
        ctaCopy: t('disable'),
        buttonDisabled:
          !!editing || (data?.channel?.mostRecentVLifeConfig?.isDisabled ?? undefined),
        onClickCta: onActivatedClick,
      };
    case GraphQLVLifeStatus.ActivePaid:
      return {
        vLifeStatus: VLifeStatus.PAID,
        title: t('v-life'),
        description: data?.channel?.mostRecentVLifeConfig?.isDisabled
          ? t('paid-future', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig.expiryDateCommToCustomer),
            })
          : t('paid'),
        ctaColor: 'primary',
        ctaCopy: t('disable'),
        buttonDisabled:
          !!editing || (data?.channel?.mostRecentVLifeConfig?.isDisabled ?? undefined),
        onClickCta: onActivatedClick,
      };
    case GraphQLVLifeStatus.ActiveFoc:
      return {
        vLifeStatus: VLifeStatus.FREE_OF_CHARGE,
        title: t('v-life'),
        description: data?.channel?.mostRecentVLifeConfig?.isDisabled
          ? t('free-of-charge-future', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig.expiryDateCommToCustomer),
            })
          : t('free-of-charge'),
        ctaColor: 'primary',
        ctaCopy: t('disable'),
        buttonDisabled:
          !!editing || (data?.channel?.mostRecentVLifeConfig?.isDisabled ?? undefined),
        onClickCta: onActivatedClick,
      };
    case GraphQLVLifeStatus.ActiveNonRefundable:
      return {
        vLifeStatus: VLifeStatus.NON_REFUNDABLE,
        title: t('v-life'),
        description: data?.channel?.mostRecentVLifeConfig?.isDisabled
          ? t('non-refundable-future', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig.expiryDateCommToCustomer),
            })
          : t('non-refundable'),
        ctaColor: 'primary',
        ctaCopy: t('disable'),
        buttonDisabled:
          !!editing || (data?.channel?.mostRecentVLifeConfig?.isDisabled ?? undefined),
        onClickCta: onActivatedClick,
      };
    case GraphQLVLifeStatus.Suspended:
      return {
        vLifeStatus: VLifeStatus.SUSPENDED,
        title: t('v-life'),
        ctaColor: 'secondary',
        ctaCopy: t('renew-licence'),
        description: data?.channel?.mostRecentVLifeConfig?.resumptionDate
          ? t('suspended-with-date', {
              date: formatDate(data?.channel?.mostRecentVLifeConfig?.resumptionDate),
            })
          : t('suspended'),
        buttonDisabled: !!editing,
        onClickCta: onDeactivatedClick,
      };
    case GraphQLVLifeStatus.Disabled:
      return {
        vLifeStatus: VLifeStatus.DEACTIVATED,
        title: t('v-life'),
        description: t('deactivated'),
        ctaColor: 'secondary',
        ctaCopy: t('renew-licence'),
        buttonDisabled: !!editing,
        onClickCta: onDeactivatedClick,
      };
    default:
      return {
        // DEFAULT EXPIRED
        vLifeStatus: VLifeStatus.INACTIVE,
        title: t('v-life'),
        description: t('expired'),
        ctaColor: 'secondary',
        ctaCopy: t('renew-licence'),
        buttonDisabled: !!editing,
        onClickCta: onDeactivatedClick,
      };
  }
}

import { Person, VLifeConfig } from '~/__generated-asset__/graphql';
import { formatPerson } from '~/utils/format-person';
import { formatDate } from '~/utils/date-utils';
import { GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { VLifeStatusWindow } from '~/components/DataGrid/filters/custom/v-life-licences/types';
import { formatDateRange } from '~/components/DataGrid/utils';

/*
  Value Formatters should:
  - Ingest grid values parsed with the Value Getters
  - Render simple AND complex values as strings

  NOTE: Value Formatters don't change the values in the grid data, these
        only affect how the values are rendered!
 */

export function dateFormatter({ value }: { value: string }) {
  return formatDate(value);
}

export function withSuffix(suffix: string) {
  return ({ value }: { value: string }) => {
    if (!value) {
      return `–`;
    }
    return `${value} ${suffix}`;
  };
}

export function personFormatter({
  value,
}: {
  value: Pick<Person, 'forename' | 'surname' | 'id'>;
}) {
  return formatPerson(value);
}

export const formatVLifeDateRange = ({ value }: GridValueFormatterParams<VLifeConfig>) => {
  if (!value) {
    return undefined;
  }
  const { startDate, expiryDateCommToCustomer } = value;
  return formatDateRange(startDate, expiryDateCommToCustomer);
};

export const formatVLifeLicences = ({ value }: GridValueFormatterParams<VLifeConfig[]>) => {
  if (!value) {
    return undefined;
  }
  return value
    .map((vLifeConfig) =>
      formatDateRange(vLifeConfig.startDate, vLifeConfig.expiryDateCommToCustomer)
    )
    .join(', ');
};

export const formatVLifeStatusWindows = ({
  value,
}: GridValueFormatterParams<VLifeStatusWindow[]>) => {
  if (!value) {
    return undefined;
  }
  return value
    .map((vLifeStatusWindow) =>
      formatDateRange(
        vLifeStatusWindow.startDate?.toISOString(),
        vLifeStatusWindow.endDate?.toISOString()
      )
    )
    .join(', ');
};

export const formatBoolean = (
  trueText: string,
  falseText: string,
  { value }: { value?: boolean }
) => {
  if (typeof value === 'undefined') {
    return '–';
  }
  return value ? trueText : falseText;
};

import { VLifeConfig } from '~/__generated-asset__/graphql';
import { VLifeConfigWithStatus, VLifeStatus } from '~/pages/CustomerAssets/types';

interface GetStatusParams {
  upcomingVLifeConfig?: Omit<VLifeConfig, 'channel'>;
  mostRecentVLifeConfig?: Omit<VLifeConfig, 'channel'> | VLifeConfigWithStatus;
}

export const getVLifeConfigStatus = ({
  mostRecentVLifeConfig,
  upcomingVLifeConfig,
}: GetStatusParams): VLifeStatus => {
  const isDisabled = !!mostRecentVLifeConfig?.isDisabled;
  const isExpired = !!mostRecentVLifeConfig?.isExpired;
  const hasRecent = !!mostRecentVLifeConfig?.startDate;
  const hasUpcoming = !!upcomingVLifeConfig?.startDate;
  const hasDeactivationReason = !!mostRecentVLifeConfig?.deactivationReason?.name;
  const activeStatus = Number(mostRecentVLifeConfig?.vlifeLicence.vlifeLicenceType.id ?? -1);

  const neverSetUp = !hasUpcoming && !hasRecent;
  const inactive = (!isDisabled && isExpired) || (!hasRecent && hasUpcoming);
  const suspended = isDisabled && isExpired && !hasDeactivationReason;
  const deactivated = isDisabled && isExpired && hasDeactivationReason;
  const active = !inactive && !(suspended || deactivated);

  if (neverSetUp) {
    return VLifeStatus.NEVER_SET_UP;
  } else if (suspended) {
    return VLifeStatus.SUSPENDED;
  } else if (deactivated) {
    return VLifeStatus.DEACTIVATED;
  } else if (inactive) {
    return VLifeStatus.INACTIVE;
  } else if (active) {
    return activeStatus;
  }
  return VLifeStatus.INACTIVE;
};

export const isLicenceActive = (
  vLifeConfig?: Omit<VLifeConfig, 'channel'> | null
): boolean => {
  if (!vLifeConfig) {
    return false;
  }
  const vLifeStatus = getVLifeConfigStatus({ mostRecentVLifeConfig: vLifeConfig });
  return [
    VLifeStatus.PAID,
    VLifeStatus.TRIAL,
    VLifeStatus.FREE_OF_CHARGE,
    VLifeStatus.NON_REFUNDABLE,
  ].includes(vLifeStatus);
};

interface ObjectWithStartDate {
  startDate?: string | Date;
  endDate?: string | Date;
}

export const sortByStartDate = (a: ObjectWithStartDate, b: ObjectWithStartDate) => {
  if (!a.startDate) {
    return -1;
  }

  if (!b.startDate) {
    return 1;
  }

  return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
};
